<template>
  <div style="margin: 20px" class="integralSetup">
    <div class="integralSetup-container">
      <div class="config-item">
        <div class="config-title">积分名称配置</div>

        <div class="item-container">
          <div class="item-label">积分名称：</div>
          <div class="input-area">
            <el-input
              v-model.trim="configName"
              class="input"
              size="small"
              placeholder="请输入名称"
              maxlength="10"
              style="width: 268px"
            ></el-input>
            <span class="toast-text">最多10个字</span>
          </div>
        </div>
      </div>

      <div class="config-item">
        <div class="config-title">积分返赠比例配置</div>

        <div class="item-container">
          <div class="item-label">积分返赠比例：</div>
          <div class="input-area">
            <div style="display: flex">
              <span style="width: 50px; margin-top: 7px">1元返</span>
              <div class="rebateRatio-input">
                <el-input
                  v-model.trim="rebateRatio"
                  style="width: 100px"
                  class="input"
                  size="small"
                  :maxlength="5"
                ></el-input
                ><span style="line-height: 32px; margin-right: 10px">分</span>
              </div>
            </div>

            <span class="toast-text">仅支持输入整数，最多5位数</span>
          </div>
        </div>
      </div>

      <div class="config-item">
        <div class="config-title">积分周期配置</div>

        <div class="item-container">
          <div class="item-label">积分有效期：</div>

          <div class="input-area">
            <div class="year-input" style="display: flex">
              <el-input
                v-model="validityPeriod"
                style="width: 120px"
                class="input"
                size="small"
                :disabled="true"
              ></el-input
              ><span style="line-height: 32px">年</span>
            </div>

            <span class="toast-text">仅支持输入整数，最多5位数</span>
          </div>
        </div>

        <div class="item-container">
          <div class="item-label">积分清零时间：</div>

          <div class="input-area">
            <div style="display: flex">
              <el-input v-model="clearTime" style="width: 150px" class="input" size="small" :disabled="true"></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottomButton">
      <el-button type="primary" size="small" @click="submit">提交</el-button>
    </div>
  </div>
</template>

<script>
import { submitIntegralSetup, getIntegralSetupDetail } from '@/api/integral'

export default {
  name: '',
  data() {
    return {
      validityPeriod: 1,
      clearTime: '12-31',
      configName: '',
      rebateRatio: '',
    }
  },
  mounted() {
    this.getSetupDetail()
  },
  methods: {
    checkrRebateRatio() {
      if (!String(this.rebateRatio).match(/^([1-9][0-9]{0,4})$/)) {
        this.$message({
          message: '积分返赠比例仅支持输入整数，最多5位数',
          type: 'error',
        })
        return false
      } else {
        return true
      }
    },

    submit() {
      if (!this.checkrRebateRatio()) {
        return
      }

      if (!this.configName) {
        this.$message({
          message: '请输入积分配置名称',
          type: 'error',
        })

        return
      }

      const reqObj = {
        data: {
          rebateRatio: this.rebateRatio,
          configName: this.configName,
          clearTime: this.clearTime,
          validityPeriod: this.validityPeriod,
        },
      }

      submitIntegralSetup(reqObj)
        .then((res) => {
          if (res.success) {
            this.$message({
              message: '保存成功',
              type: 'success',
            })
            this.getSetupDetail()
          }
        })
        .catch((err) => console.log(err))
    },

    getSetupDetail() {
      getIntegralSetupDetail({ data: {} })
        .then((res) => {
          if (res.success) {
            const data = res.data || {}
            this.rebateRatio = data.rebateRatio
            this.configName = data.configName
          }
        })
        .catch((err) => console.log(err))
    },
  },
}
</script>

<style lang="less" scoped>
.integralSetup {
  background-color: #fff;
  width: 100%;
  height: 100vh;

  .integralSetup-container {
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    .config-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      .config-title {
        font-size: 16px;
        color: #111111;
        margin-left: 20px;
        margin-bottom: 17px;
        position: relative;
      }

      .config-title::before {
        content: '';
        width: 3px;
        height: 22px;
        position: absolute;
        left: -20px;
        top: 0;
        background: #2861e2;
      }

      .config-title::after {
        content: '';
        width: 100%;
        height: 1px;
        background: #e0e0e0;
        display: block;
        position: absolute;
        bottom: -17px;
      }

      .item-container {
        display: flex;
        margin: 20px 0 0 40px;

        .item-label {
          font-size: 14px;
          color: #363f47;
          position: relative;
          margin-top: 7px;
        }

        .item-label::before {
          content: '*';
          color: red;
          position: absolute;
          width: 3px;
          height: 3px;
          left: -7px;
          top: 4px;
        }

        .input-area {
          display: flex;
          flex-direction: column;

          .year-input {
            border: 1px solid #ccc;
            border-radius: 4px;
            background-color: #f5f7fa;

            ::v-deep .el-input {
              input {
                border: none;
              }
            }
          }

          .rebateRatio-input {
            border: 1px solid #ccc;
            border-radius: 4px;

            ::v-deep .el-input {
              input {
                border: none;
              }
            }
          }

          .toast-text {
            font-size: 12px;
            color: #9a9d9e;
            margin-top: 5px;
          }
        }
      }
    }
  }

  .bottomButton {
    margin-left: 40px;
  }
}
</style>
